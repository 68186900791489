.staff_login_container {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/staff_page/staff_login_background.png');
    object-fit: cover;
    background-position: center;
}
.staff_login_fade_effect {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(to top, rgb(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.staff_dashboard_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 10px;
    row-gap: 10px;
}
.staff_line_light {
    height: 1px;
    width: 80%;
    background-color: var(--theme-color-silver);
}

/* 
Add margin to the sort icon of PrimeReact library datatable
Defult icon position stick to header, so add more margin into it
*/
.p-sortable-column-icon {
    margin-left: 10px;
    transform: translateY(-4px);
}

/* xl */
@media screen and (max-width: 1280px) {
    .staff_dashboard_container {
        grid-template-columns: 1fr;
    }
}