@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Customize radio buttons */
/* Hide the Default Radio Button */
input[type="radio"], input[type="checkbox"] {
  appearance: none;
  position: relative;
}
/* Create a Custom Radio Button */
input[type="radio"]:before, input[type="checkbox"]:before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #c0c0c0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;  /* Default background to white */
}
input[type="checkbox"]:before {
  border-radius: 0.125rem;
}
/* Customize the Checked State */
input[type="radio"]:checked:before, input[type="checkbox"]:checked:before {
  width: 18px;
  height: 18px;
  background-color: white;
  border: 2px solid #fe9d6a;
}
/* White circle in the middle of the checked radio button */
input[type="radio"]:checked:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 4px;
  left: 4px;
}
input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid #fe9d6a;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.notoser-exbold {
  font-family: 'notoser_exbold';
}

.notoser-bold {
  font-family: 'notoser_bold';
}

.notoser-semi {
  font-family: 'notoser_semibold';
}

.notoser-med {
  font-family: 'notoser_medium';
}

.notoser-reg {
  font-family: 'notoser_regular';
}

.notoser-light {
  font-family: 'notoser_light';
}

.notoser-exlight {
  font-family: 'notoser_exlight';
}

.notoser-thin {
  font-family: 'notoser_thin';
}

@font-face {
  font-family: 'notoser_bold';
  src: local('NotoSerifThai-Bold'),
       url('./fonts/NotoSerif/NotoSerifThai-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_exbold';
  src: local('NotoSerifThai-ExtraBold'),
       url('./fonts/NotoSerif/NotoSerifThai-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_exlight';
  src: local('NotoSerifThai-ExtraLight'),
       url('./fonts/NotoSerif/NotoSerifThai-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_light';
  src: local('NotoSerifThai-Light'),
       url('./fonts/NotoSerif/NotoSerifThai-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_medium';
  src: local('NotoSerifThai-Medium'),
       url('./fonts/NotoSerif/NotoSerifThai-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_regular';
  src: local('NotoSerifThai-Regular'),
       url('./fonts/NotoSerif/NotoSerifThai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_semibold';
  src: local('NotoSerifThai-SemiBold'),
       url('./fonts/NotoSerif/NotoSerifThai-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'notoser_thin';
  src: local('NotoSerifThai-Thin'),
       url('./fonts/NotoSerif/NotoSerifThai-Thin.ttf') format('truetype');
}

.tav-exbold {
  font-family: 'tav_exbold';
}

.tav-bold {
  font-family: 'tav_bold';
}

.tav-semi {
  font-family: 'tav_semibold';
}

.tav-med {
  font-family: 'tav_medium';
}

.tav-reg {
  font-family: 'tav_regular';
}

.tav-light {
  font-family: 'tav_light';
}

.tav-exlight {
  font-family: 'tav_exlight';
}

.tav-thin {
  font-family: 'tav_thin';
}

@font-face {
  font-family: 'tav_bold';
  src: local('Taviraj-Bold'),
       url('./fonts/Taviraj/Taviraj-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_exbold';
  src: local('Taviraj-ExtraBold'),
       url('./fonts/Taviraj/Taviraj-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_exlight';
  src: local('Taviraj-ExtraLight'),
       url('./fonts/Taviraj/Taviraj-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_light';
  src: local('Taviraj-Light'),
       url('./fonts/Taviraj/Taviraj-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_medium';
  src: local('Taviraj-Medium'),
       url('./fonts/Taviraj/Taviraj-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_regular';
  src: local('Taviraj-Regular'),
       url('./fonts/Taviraj/Taviraj-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_semibold';
  src: local('Taviraj-SemiBold'),
       url('./fonts/Taviraj/Taviraj-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'tav_thin';
  src: local('Taviraj-Thin'),
       url('./fonts/Taviraj/Taviraj-Thin.ttf') format('truetype');
}